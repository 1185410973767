import * as React from 'react';
import './MindPaper.css';
import { AppStore } from '../../stores';
import { inject, observer } from 'mobx-react';
import HTMLFlipBook from "react-pageflip";
import ProductService from '../../services/ProductService';
import { INote, INotePage } from '../../interfaces/INote';
import ReactQuill from 'react-quill';
import { IMindPaper } from '../../interfaces/IMindPaper';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Link } from 'react-router-dom';

export interface IProps {
  appStore?: AppStore;
}

export interface IState {
  mindPaper: any;
  loaded: boolean;
  page: number;
  wantedPage?: number;
  layout: "singlePage" | "doublePage";
  zoom: number;
  showNote: boolean;
  saving: boolean;
  saved: boolean;
  noteMode: "overview" | "write";
  mousePosition?: { x: number, y: number };
  isDragging: boolean;
}

@inject('appStore')
@observer
export default class MindPaper extends React.Component<IProps, IState> {

  private book;

  constructor(props) {
    super(props);
    this.state = {
      mindPaper: undefined,
      loaded: false,
      page: this.props.appStore.isMobile ? 1 : 0,
      layout: this.props.appStore.isMobile ? "singlePage" : "doublePage",
      zoom: 1,
      showNote: false,
      saving: false,
      saved: false,
      noteMode: "write",
      isDragging: false
    }
  }

  componentDidMount(): void {
    /*To Prevent right click on screen*/
    document.addEventListener("contextmenu", (event) => {
      event.preventDefault();
    });
    const urlParams = new URLSearchParams(window.location.search);
    const mindPaperId = urlParams.get('mindPaperId');
    this.setState({ loaded: false });
    ProductService.getMindPaper(mindPaperId).then((mindPaper: IMindPaper) => {
      this.setState({ mindPaper });
      setTimeout(() => {
        this.setState({ loaded: true });
      }, 1000);
      const mindPaperName = mindPaper.url && mindPaper.url.split("/");
      this.props.appStore.mindPaperName = mindPaperName[mindPaperName.length - 1];
      this.props.appStore.noteToEdit = {
        userId: this.props.appStore.user.id,
        mindPaperId: mindPaperId,
      };
      if (urlParams.get('printMode')) {
        this.props.appStore.printMode = true;
      } else {
        this.props.appStore.noteToEdit.notes = [];
        for (var i = 0; i < mindPaper.pages; i++) {
          this.props.appStore.noteToEdit.notes.push(
            {
              pageNumber: i,
              text: ""
            }
          );
        }
        ProductService.getNote(this.props.appStore.noteToEdit).then((note: INote) => {
          if (note) {
            this.props.appStore.noteToEdit.objectId = note.objectId;
            this.props.appStore.noteToEdit.notes.forEach((notePage: INotePage) => {
              notePage.noteId = note.objectId;
              notePage.text = note.notes && note.notes[notePage.pageNumber] ? note.notes[notePage.pageNumber].text : "" // For at hente gamle noter
            });
            ProductService.getNotePages(note.objectId).then((notePages: INotePage[]) => {
              if (notePages) {
                notePages.forEach((notePage: INotePage) => {
                  this.props.appStore.noteToEdit.notes[notePage.pageNumber] = notePage;
                });
              }
            });
          } else {
            ProductService.createNote(this.props.appStore.noteToEdit).then(() => {
              ProductService.getNote(this.props.appStore.noteToEdit).then((note: INote) => {
                if (note) {
                  this.props.appStore.noteToEdit.objectId = note.objectId;
                  this.props.appStore.noteToEdit.notes.forEach((notePage: INotePage) => {
                    notePage.noteId = note.objectId;
                  });
                }
              });
            });
          }
        });
      }
    });
  }

  private nextPage(): void {
    this.book.pageFlip().flipNext();
  }

  private previousPage(): void {
    this.book.pageFlip().flipPrev();
  }

  private turnToPage(pageNumber: number, transition: "auto" | "smooth", height?: number): void {
    this.setState({ page: pageNumber });
    if (this.state.layout === "doublePage") {
      if (this.book && this.book.pageFlip()) {
        this.book.pageFlip().turnToPage(pageNumber - 1);
      }
    } else {
      if (this.props.appStore.isMobile) {
        let element: any = document.getElementsByClassName("react-transform-component")[0];
        const values = element.style.transform.split(/\w+\(|\);?/);
        const top = values[1].split(",")[1].replace("px", "");
        element.style.transform = `translate3d(0px, -${height * (pageNumber - 1)}px, 0px) scale(1)`;
      } else {
        document.getElementsByClassName("mindPaperContainer")[0].scrollTo({
          behavior: transition,
          top: (height + 20) * (pageNumber - 1)
        });
        setTimeout(() => {
          this.setState({ page: pageNumber });
        }, 500);
      }
    }
  }

  private hasNote(pageIndex: number): boolean {
    if (this.props.appStore.noteToEdit && this.props.appStore.noteToEdit.notes && this.props.appStore.noteToEdit.notes.length > pageIndex) {
      return this.props.appStore.noteToEdit.notes[pageIndex] != undefined && this.props.appStore.noteToEdit.notes[pageIndex].text !== "" && this.props.appStore.noteToEdit.notes[pageIndex].text !== "<p><br></p>";
    }
  }

  private getMobilePage(height: number): void {
    let element: any = document.getElementsByClassName("react-transform-component")[0];
    const values = element.style.transform.split(/\w+\(|\);?/);
    const top = values[1].split(",")[1].replace("px", "");
    if (top) {
      const scrollTop = (parseInt(top) * -1) / parseInt(values[3]);
      this.setState({ page: Math.ceil(scrollTop / height) });
    }
  }

  private printPdf(): void {
    const objFra: any = document.getElementById('myFrame');
    objFra.contentWindow.focus();
    objFra.contentWindow.print();
  }

  render() {
    let wrapperHeight = Math.floor(window.innerHeight - 100);
    if (this.state.layout === "singlePage") {
      wrapperHeight = window.innerHeight;
    }
    let wrapperWidth = Math.floor(wrapperHeight / 1.4);
    if (this.props.appStore.isMobile) {
      if (this.state.layout === "doublePage") {
        wrapperWidth = (window.innerWidth / 2) - 10;
        wrapperHeight = wrapperWidth * 1.4;
      } else {
        wrapperWidth = window.innerWidth - 20;
        wrapperHeight = window.innerHeight - 80;
      }
      if (window.platform == "ios") {
        wrapperHeight = wrapperHeight + 100
      }
    }
    let height = wrapperHeight * this.state.zoom;
    let width = Math.floor(height / 1.4);
    if (this.props.appStore.isMobile) {
      width = wrapperWidth;
      height = width * 1.4;
    }
    if (this.state.zoom > 1 && !this.props.appStore.isMobile) {
      if (width < window.innerWidth - 270) {
        wrapperWidth = width;
      } else {
        wrapperWidth = window.innerWidth - 270;
      }
    }
    const pages = [];
    const printPages = [];
    const pageThumbnails = [];
    const bookmarkSize = 40;
    if (this.state.mindPaper) {
      for (let p = 1; p <= this.state.mindPaper.pages; p++) {
        printPages.push(
          <div className='mindPaperPrintPage'>
            <img
              src={`${this.state.mindPaper.url}/${this.state.mindPaper.fileName}_Side_${p < 10 && this.state.mindPaper.pages >= 10 ? "0" + p : p}.jpg`}
              width={"100%"}
              height={"100%"}
            />
          </div>
        )
        pages.push(
          <div
            className="page"
            style={{
              marginBottom: this.state.layout === "doublePage" ? 0 : (p === this.state.mindPaper.pages ? height : 20),
              width: width,
              height: height
            }}
          >
            <img
              src={`${this.state.mindPaper.url}/${this.state.mindPaper.fileName}_Side_${p < 10 && this.state.mindPaper.pages >= 10 ? "0" + p : p}.jpg`}
              width={width}
              height={height}
            />
            {this.hasNote(p) && this.state.layout === "singlePage" &&
              <div
                style={{
                  marginTop: -(height - 50),
                  position: "relative",
                  marginLeft: this.props.appStore.isMobile ? width - bookmarkSize : width,
                  width: bookmarkSize,
                  height: bookmarkSize,
                  backgroundColor: "#ee716e",
                  color: "#ffffff",
                  textAlign: 'center',
                  lineHeight: bookmarkSize + "px",
                  boxSizing: "border-box",
                  boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  this.setState({ showNote: !this.state.showNote })
                }}
              >
                <div
                  style={{
                    backgroundImage: 'url("https://img.icons8.com/ios-glyphs/90/000000/inscription.png")',
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: 30,
                    filter: "invert(100)",
                    width: "100%",
                    height: "100%",
                  }}
                />
              </div>
            }
          </div>
        );
        pageThumbnails.push(
          <div
            className="thumbnailPage"
            style={{
              backgroundImage: `url("${this.state.mindPaper.url}/${this.state.mindPaper.fileName}_Side_${p < 10 && this.state.mindPaper.pages >= 10 ? "0" + p : p}.jpg")`,
              float: this.state.layout === "singlePage" || p % 2 === 0 || this.props.appStore.isMobile ? "left" : "right",
              clear: (this.state.layout === "singlePage" || p % 2 === 0) && !this.props.appStore.isMobile ? "both" : "none",
              marginLeft: this.state.layout === "doublePage" ? 0 : (this.props.appStore.isMobile ? 5 : 20),
              opacity: this.state.layout === "singlePage" ? (this.state.page === p ? 1 : 0.5) : (this.state.page === p - 1 || (this.state.page === p - 2 && this.state.page !== 0) ? 1 : 0.5),
              height: this.props.appStore.isMobile ? 50 : 70,
              width: this.props.appStore.isMobile && p % 2 === 0 && this.state.layout === "doublePage" ? 35 : 40
            }}
            onClick={() => this.turnToPage(p, "smooth", height)}
          >
            {this.hasNote(p) &&
              (this.props.appStore.isMobile ?
                <div
                  style={{
                    marginTop: 5,
                    position: "relative",
                    float: this.state.layout === "doublePage" && p % 2 === 0 ? "left" : "right",
                    width: 5,
                    height: 5,
                    backgroundColor: "#ee716e",
                    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                    marginRight: 5,
                    marginLeft: this.state.layout === "doublePage" && p % 2 === 0 ? 0 : 0
                  }}
                />
                :
                <div
                  style={{
                    marginTop: 5,
                    position: "relative",
                    float: this.state.layout === "doublePage" && p % 2 === 0 ? "left" : "right",
                    width: 5,
                    height: 5,
                    backgroundColor: "#ee716e",
                    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                    marginRight: -5,
                    marginLeft: -5
                  }}
                />
              )
            }
          </div>
        );
      }
    }
    const notes = [];
    if (this.props.appStore.noteToEdit && this.props.appStore.noteToEdit.notes && this.props.appStore.noteToEdit.notes.length > 0) {
      this.props.appStore.noteToEdit.notes.forEach((note: { text: string }, index) => {
        if (note && note.text !== "<p><br></p>" && note.text !== "" && index > 0) {
          notes.push(
            <div
              className='mindPaperNote'
              onClick={() => {
                this.turnToPage(index, "smooth", height);
                this.setState({ noteMode: "write" });
              }}
            >
              <div className='mindPaperNotePageNumber'>{`Side ${index}:`}</div>
              <div className='mindPaperNoteEditButton'>
                <button
                  style={{
                    float: "right"
                  }}
                  onClick={() => {
                  }}
                >
                  {window.loc.strings.mindPaperEdit}
                </button>
              </div>
              <div className='mindPaperNoteContent' dangerouslySetInnerHTML={{ __html: note.text }} />
            </div>
          );
        }
      })
    }
    let marginLeft;
    if (this.state.mindPaper) {
      if (this.state.layout === "doublePage") {
        marginLeft = this.state.page === 0 ? (this.state.mindPaper.pages === "1" ? `calc(50% - ${wrapperWidth * 0.5}px)` : `calc(50% - ${wrapperWidth * 1.5}px)`) : `calc(50% - ${wrapperWidth}px)`;
      } else {
        if (width > (screen.width - ((screen.width / 2) - wrapperWidth / 2))) {
          marginLeft = 0;
        } else {
          marginLeft = `calc(50% - ${wrapperWidth / 2}px)`;
        }
      }
    }
    let content;
    if (this.state.mindPaper) {
      content = (
        <div
          className='mindPaperContainer'
          style={{
            opacity: this.state.loaded ? 1 : 0,
            transition: "all 500ms ease-in-out",
            width: this.state.layout === "doublePage" ? wrapperWidth * 2 : "100%",
            height: this.props.appStore.isMobile ? "auto" : wrapperHeight,
            marginLeft: marginLeft,
            overflowY: this.state.layout === "doublePage" ? "unset" : "auto",
            overflowX: this.state.layout === "doublePage" ? "unset" : (wrapperWidth === window.innerWidth - 270 ? "scroll" : "hidden"),
            paddingTop: this.props.appStore.isMobile ? (this.state.layout === "doublePage" ? "25vh" : 110) : 50,
          }}
          onScroll={() => {
            const scrollTop = Math.round(document.getElementsByClassName("mindPaperContainer")[0].scrollTop);
            if (scrollTop > height * (this.state.page)) {
              this.setState({ page: this.state.page + 1 });
            } else if (scrollTop < (height * (this.state.page - 1) - (0.5 * height))) {
              this.setState({ page: this.state.page - 1 });
            }
          }}
        >
          {this.state.layout === "doublePage" ?
            <>
              <HTMLFlipBook
                style={{
                  zIndex: 1000000
                }}
                showCover
                width={width}
                height={height}
                onInit={(e) => {
                  console.log("Mode: " + e.data.mode);
                  console.log(e.object.pages.pages.length);
                  console.log(e.object);
                }}
                ref={(component) => (this.book = component)}
                onFlip={(e) => {
                  console.log(e.data);
                  this.setState({ page: e.data });
                }}
              >
                {pages}
              </HTMLFlipBook>
            </>
            :
            <>{pages}</>
          }
        </div>
      );
    }
    return this.state.mindPaper ? (
      (this.props.appStore.printMode ?
        <div
          className='mindPaperPrintPages'
          style={{
            height: this.state.mindPaper?.pages * 1020
          }}
        >
          {printPages}
        </div>
        :
        <>
          {this.props.appStore.isMobile && this.state.layout === "singlePage" ?
            <TransformWrapper
              initialScale={1}
              onPanningStop={() => {
                this.getMobilePage(height);
              }}
            >
              {({ zoomIn, zoomOut, resetTransform }) => (
                <React.Fragment>
                  <TransformComponent
                    wrapperStyle={{
                      position: "absolute",
                      width: "100%",
                      height: "100%"
                    }}
                    contentStyle={{
                      width: "100%"
                    }}
                  >
                    {content}
                  </TransformComponent>
                </React.Fragment>
              )}
            </TransformWrapper>
            :
            content
          }
          {this.props.appStore.isMobile && this.state.showNote &&
            <div
              className="noteBoxBg"
              onClick={() => this.setState({ showNote: false })}
            />
          }
          <div
            id="NoteBox"
            className="noteBox"
            style={{
              top: this.props.appStore.isMobile ? 100 : this.state.mousePosition?.y ? (this.state.mousePosition?.y - 30) : 90,
              left: this.state.showNote ? (this.props.appStore.isMobile ? 15 : (this.state.mousePosition?.x ? (this.state.mousePosition?.x - 394) : window.innerWidth - 520)) : -window.innerWidth,
              width: this.props.appStore.isMobile ? window.innerWidth - 30 : 500,
              opacity: this.state.showNote ? 1 : 0,
              height: (window.innerHeight / 2) + 100
            }}
          >
            <div className='noteTabs'>
              <div
                className='noteTabWrite'
                style={{
                  backgroundColor: this.state.noteMode === "write" ? "white" : "lightgray"
                }}
                onClick={() => this.setState({ noteMode: "write" })}
              >
                {window.loc.strings.mindPaperWrite}
              </div>
              <div
                className='noteTabOverview'
                style={{
                  backgroundColor: this.state.noteMode === "overview" ? "white" : "lightgray"
                }}
                onClick={() => this.setState({ noteMode: "overview" })}
              >
                {window.loc.strings.mindPaperOverview}
              </div>
            </div>
            {this.state.noteMode === "overview" ?
              <div className='noteModeOverview'>
                {notes}
              </div>
              :
              <div className='noteModeWrite'>
                <div className='notePageNumber'><span style={{ fontFamily: "Geogtq-Md", fontWeight: 900 }}>{this.state.page}</span>{` / ${pages.length}`}</div>
                <div className="noteCloseButton"
                  onClick={() => this.setState({ showNote: false })}
                />
                {this.state.saving ?
                  <div style={{ float: "right", marginTop: 5 }}>
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 100 100" enableBackground="new 0 0 100 100">
                      <polyline className="check" fill="none" stroke="#122c43" strokeWidth="10" strokeMiterlimit="20" points="15,60 40,80 85,20" style={{ animation: this.state.saved ? "dash 500ms forwards" : "none" }} />
                    </svg>
                  </div>
                  :
                  <div
                    className="noteSaveButton"
                    style={{
                      opacity: this.state.saving ? 0.5 : 1
                    }}
                    onClick={() => {
                      this.setState({ saving: true });
                      if (this.props.appStore.noteToEdit.objectId) {
                        if (this.props.appStore.noteToEdit.notes[this.state.page].objectId) {
                          ProductService.updateNotePage(this.props.appStore.noteToEdit.notes[this.state.page]).then(() => {
                            this.setState({ saved: true });
                            setTimeout(() => {
                              this.setState({ saved: false, saving: false, });
                            }, 1000);
                          });
                        } else {
                          ProductService.createNotePage(this.props.appStore.noteToEdit.notes[this.state.page]).then(() => {
                            this.setState({ saved: true });
                            setTimeout(() => {
                              this.setState({ saved: false, saving: false, });
                            }, 1000);
                          });
                        }

                      } else {
                        ProductService.createNote(this.props.appStore.noteToEdit).then(() => {
                          // TODO: Id mangler. Måske skal note oprettes, hvis den ikke findes?
                          ProductService.createNotePage(this.props.appStore.noteToEdit.notes[this.state.page]).then(() => {
                            this.setState({ saved: true });
                            setTimeout(() => {
                              this.setState({ saved: false, saving: false, });
                            }, 1000);
                          });
                        });
                      }
                    }}
                  >
                    {window.loc.strings.mindPaperSave}
                  </div>
                }
                {!this.props.appStore.isMobile &&
                  <div
                    className="dragbutton"
                    draggable
                    onMouseUp={(e) => {
                      e.preventDefault();
                    }}
                    onDragStart={() => {
                      this.setState({ isDragging: true });
                    }}

                    onDragEnd={() => {
                      this.setState({ isDragging: false });
                    }}
                    onDrag={(e) => {
                      e.preventDefault();
                      if (e.clientX !== 0) {
                        this.setState({
                          mousePosition: { x: e.clientX, y: e.clientY }
                        });
                      }
                    }}
                    style={{
                      opacity: this.state.isDragging ? 0 : 0.5
                    }}
                  />
                }
                <ReactQuill
                  theme="snow"
                  value={this.props.appStore.noteToEdit && this.props.appStore.noteToEdit.notes && this.props.appStore.noteToEdit.notes[this.state.page] && this.props.appStore.noteToEdit.notes[this.state.page].text ? this.props.appStore.noteToEdit.notes[this.state.page].text : ""}
                  modules={{
                    clipboard: {
                      matchVisual: false
                    },
                    toolbar: [
                      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                      [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                      ['clean']                                         // remove formatting button
                    ]
                  }}
                  onChange={(html) => {
                    this.props.appStore.noteToEdit.notes[this.state.page].text = html;
                  }}
                  style={{
                    width: "100%",
                    height: window.innerHeight / 2,
                    float: "left",
                    position: "relative",
                    marginTop: 5
                  }}
                />
              </div>
            }
          </div>
          {this.state.layout === "doublePage" &&
            <>
              <div
                className='previousPageButton'
                style={{
                  marginTop: this.props.appStore.isMobile ? 20 : -((height / 2) + 25),
                  opacity: this.state.page === 0 ? 0 : 1
                }}
                onClick={() => this.previousPage()}
              />
              <div
                className='nextPageButton'
                style={{
                  marginTop: this.props.appStore.isMobile ? 20 : -((height / 2) + 25),
                  opacity: this.state.page === this.state.mindPaper.pages - 1 ? 0 : 1,
                  marginRight: this.props.appStore.isMobile ? (this.state.page === 0 ? "20vw" : "30vw") : -80
                }}
                onClick={() => this.nextPage()}
              />
            </>
          }
          {!this.props.appStore.isMobile &&
            <div
              className='contentMap'
              style={{
                opacity: this.state.loaded ? 1 : 0,
                transition: "opacity 500ms ease-in-out",
                height: wrapperHeight,
                zIndex: 1000001,
              }}
            >
              <div className={'pagenumber'}>
                <input
                  id="mindPaperPageInput"
                  type='text'
                  value={this.state.wantedPage ? this.state.wantedPage : (this.state.layout === "doublePage" ? this.state.page + 1 : this.state.page)}
                  onChange={(event: any) => {
                    if (event.target.value && event.target.value !== "" && typeof parseInt(event.target.value) === 'number' && parseInt(event.target.value) > 0 && parseInt(event.target.value) <= pages.length) {
                      this.setState({ wantedPage: event.target.value });
                      setTimeout(() => {
                        const mindPaperPageInput: any = document.getElementById("mindPaperPageInput");
                        mindPaperPageInput.blur();
                      }, 500);
                    }
                  }}
                  onBlur={() => {
                    if (this.state.wantedPage) {
                      this.turnToPage(this.state.wantedPage, "smooth", height);
                      setTimeout(() => {
                        this.setState({ wantedPage: undefined });
                      }, 1000);
                    }
                  }}
                  style={{
                    height: 25,
                    borderRadius: 10,
                    width: 30,
                    border: "none",
                    fontSize: 18,
                    textAlign: "center",
                    outline: "none",
                    marginRight: 2
                  }}
                />
                <span>{` / ${pages.length}`}</span>
              </div>
              <div
                className='singlePage'
                style={{
                  opacity: this.state.layout === "singlePage" ? 1 : 0.5
                }}
                onClick={() => this.setState({ layout: "singlePage", zoom: 1.5 }, () => {
                  setTimeout(() => {
                    this.turnToPage(this.state.page + 1, "auto", height);
                    document.getElementsByClassName("mindPaperContainer")[0].scrollLeft = 0;
                  }, 0);
                })}
              />
              <div
                className='doublePage'
                style={{
                  opacity: this.state.layout === "doublePage" ? 1 : 0.5
                }}
                onClick={() => this.setState({ layout: "doublePage", zoom: 1, showNote: false }, () => {
                  setTimeout(() => {
                    this.turnToPage(this.state.page, "auto", height);
                    document.getElementsByClassName("mindPaperContainer")[0].scrollLeft = 0;
                  }, 0);
                })}
              />
              <div className='divider' />
              <div
                className='pageThumbnails'
                style={{
                  height: wrapperHeight - 92,
                  width: this.state.layout === "doublePage" ? 100 : 70,
                  paddingRight: this.state.layout === "doublePage" ? 10 : 0,
                  paddingLeft: this.state.layout === "doublePage" ? 10 : 0,
                  marginLeft: this.state.layout === "doublePage" ? -10 : 0
                }}
              >
                {pageThumbnails}
              </div>
            </div>
          }

          {/* {this.props.appStore.isMobile &&
            <div
              className="mobileContentMap"
              style={{
                opacity: this.state.loaded ? 1 : 0,
                transition: "opacity 500ms ease-in-out",
                bottom: window.platform === "ios" ? 20 : 90
              }}
            >
              <div className='divider' />
              <div className='pageThumbnailsWrapper'>
                <div
                  className='pageThumbnails'
                  style={{
                    height: 60,
                    width: 50 * pageThumbnails.length
                  }}
                >
                  {pageThumbnails}
                </div>
              </div>
            </div>
          } */}

          {!this.props.appStore.isMobile &&
            <div
              className="toolbox"
              style={{
                opacity: this.state.loaded ? 1 : 0,
                transition: "opacity 500ms ease-in-out",
              }}
            >
              <div
                className='zoomIn'
                style={{
                  opacity: this.state.zoom === 3 ? 0.15 : 1
                }}
                onClick={() => {
                  if (this.state.layout === "singlePage") {
                    this.state.zoom < 3 ? this.setState({ zoom: this.state.zoom + 0.5 }, () => {
                      setTimeout(() => {
                        this.turnToPage(this.state.page, "auto", height);
                      }, 0);
                    }) : {}
                  } else {
                    this.setState({ layout: "singlePage", zoom: 1.5 }, () => {
                      setTimeout(() => {
                        this.turnToPage(this.state.page + 1, "auto", height);
                      }, 0);
                    })
                  }
                }}
              />
              <div
                className='zoomOut'
                style={{
                  opacity: this.state.zoom === 1 ? 0.15 : 1
                }}
                onClick={() => {
                  this.state.zoom > 1 ? this.setState({ zoom: this.state.zoom + -0.5 }, () => {
                    setTimeout(() => {
                      this.turnToPage(this.state.page, "auto", height);
                      document.getElementsByClassName("mindPaperContainer")[0].scrollLeft = 0;
                    }, 0);
                  }) : {}
                }}
              />
              {this.state.mindPaper.printEnabled &&
                <div
                  className='print'
                  onClick={() => {
                    this.printPdf();
                    // this.props.appStore.printMode = true;
                    // setTimeout(() => {
                    //   window.print();
                    // }, 0);
                    // setTimeout(() => {
                    //   this.props.appStore.printMode = false;
                    // }, 2000);
                  }}
                />
              }
              <div
                className='note'
                style={{
                  // opacity: this.state.showNote ? 1 : 0.5
                }}
                onClick={() => {
                  if (this.state.showNote) {
                    this.setState({ showNote: false });
                  } else {
                    let layout = this.state.layout;
                    this.setState({ layout: "singlePage", showNote: true }, () => {
                      setTimeout(() => {
                        this.turnToPage(layout === "singlePage" ? this.state.page : this.state.page + 1, "auto", height);
                        document.getElementsByClassName("mindPaperContainer")[0].scrollLeft = 0;
                      }, 0);
                    });
                  }
                }}
              />
            </div>
          }
          {this.props.appStore.isMobile &&
            <div
              className="mobileToolbox"
              style={{
                opacity: this.state.loaded ? 1 : 0,
                transition: "opacity 500ms ease-in-out",
                paddingLeft: this.state.layout === "doublePage" ? 0 : 10
              }}
            >
              {this.state.layout === "doublePage" &&
                <>
                  <div className={'pagenumber'}>
                    <input
                      id="mindPaperPageInput"
                      type='text'
                      value={this.state.wantedPage ? this.state.wantedPage : (this.state.layout === "doublePage" ? this.state.page + 1 : this.state.page)}
                      onChange={(event: any) => {
                        if (event.target.value && event.target.value !== "" && typeof parseInt(event.target.value) === 'number' && parseInt(event.target.value) > 0 && parseInt(event.target.value) <= pages.length) {
                          this.setState({ wantedPage: event.target.value });
                          setTimeout(() => {
                            const mindPaperPageInput: any = document.getElementById("mindPaperPageInput");
                            mindPaperPageInput.blur();
                          }, 500);
                        }
                      }}
                      onBlur={() => {
                        if (this.state.wantedPage) {
                          this.turnToPage(this.state.wantedPage, "smooth", height);
                          setTimeout(() => {
                            this.setState({ wantedPage: undefined });
                          }, 1000);
                        }
                      }}
                      style={{
                        height: 25,
                        borderRadius: 10,
                        width: 40,
                        border: "none",
                        fontSize: 18,
                        textAlign: "center",
                        outline: "none",
                        marginRight: 2
                      }}
                    />
                    <span>{` / ${pages.length}`}</span>
                  </div>

                  <div className='divider' />
                </>
              }
              <div
                className='singlePage'
                style={{
                  opacity: this.state.layout === "singlePage" ? 1 : 0.5
                }}
                onClick={() => this.setState({ layout: "singlePage", zoom: 1, page: this.state.page + 1 }, () => {
                  setTimeout(() => {
                    this.turnToPage(this.state.page, "auto", height);
                    document.getElementsByClassName("mindPaperContainer")[0].scrollLeft = 0;
                  }, 0);
                })}
              />
              <div
                className='doublePage'
                style={{
                  opacity: this.state.layout === "doublePage" ? 1 : 0.5
                }}
                onClick={() => this.setState({ layout: "doublePage", zoom: 1, showNote: false }, () => {
                  setTimeout(() => {
                    this.turnToPage(this.state.page, "auto", height);
                    document.getElementsByClassName("mindPaperContainer")[0].scrollLeft = 0;
                  }, 0);
                })}
              />
              <div className='divider' />
              <div
                className='note'
                style={{
                  opacity: this.state.showNote ? 1 : 0.5
                }}
                onClick={() => {
                  if (this.state.showNote) {
                    this.setState({ showNote: false });
                  } else {
                    const layout = this.state.layout;
                    this.setState({ showNote: true }, () => {
                      setTimeout(() => {
                        if (layout !== "singlePage") {
                          this.turnToPage(this.state.page + 1, "auto", height);
                        } else {
                          this.turnToPage(this.state.page, "auto", height);
                        }
                        document.getElementsByClassName("mindPaperContainer")[0].scrollLeft = 0;
                      }, 0);
                    });
                  }
                }}
              />
              {/* {this.state.mindPaper.printEnabled &&
                <div
                  className='print'
                  onClick={() => {
                    this.printPdf();
                    // this.props.appStore.printMode = true;
                    // setTimeout(() => {
                    //   window.print();
                    // }, 0);
                    // setTimeout(() => {
                    //   this.props.appStore.printMode = false;
                    // }, 2000);
                  }}
                />
              } */}
            </div>
          }
          {!this.state.loaded &&
            <div className="spinner">
              <div className="spinnerLabel">{window.loc.strings.loadingMindPaper}</div>
            </div>
          }
          <iframe
            src={`${this.state.mindPaper?.url}/${this.state.mindPaper?.fileName}.pdf`}
            id="myFrame"
            width="0"
            height="0"
            style={{
              visibility: "hidden"
            }}
          />
        </>
      )
    ) : (
      <>
      </>
    );
  }
}