import * as React from 'react';
import { Link } from 'react-router-dom';
import { IModuleDocument } from '../../../interfaces/IModuleDocument';
import { Icon } from '../../UI/icon';
import AdminService from '../../../services/AdminService';
import './AccordionItem.css'

export interface IProps {
  item: any;
  backgroundColor?: string;
  hideBorder?: boolean;
  isMobile?: boolean;
  preview?: boolean;
  expandedAsDefault?: boolean;
  openInPopup: (url: string) => void;
  openInPageEmbed: (url: string) => void;
}

export interface IState {
  expanded: boolean;
  document?: IModuleDocument
}

export class AccordionItem extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      expanded: props.expandedAsDefault,
    }
    if (!props.item.title) {
      console.log(this.props.item.id);
      AdminService.getFile(this.props.item.id).then((document: IModuleDocument) => {
        this.setState({ document });
      });
    }
    setTimeout(() => {
      this.forceUpdate();
    }, 0);
  }

  public render(): JSX.Element {
    let width;
    const acccordionItem = document.getElementsByClassName("acccordionItem");
    if (acccordionItem && acccordionItem.length > 0) {
      width = acccordionItem[0].clientWidth;
    } else {
      setTimeout(() => {
        this.forceUpdate();
      }, 0)
    }
    let contentHeight = 50;
    if (document.getElementById(`accordionItemContent${this.props.item.id}`)) {
      const contentElement = document.getElementById(`accordionItemContent${this.props.item.id}`);
      contentHeight = contentElement.clientHeight + 50;
    }
    let titleWidth = "100%";
    if (this.props.item.icon || this.state.document?.icon) {
      titleWidth = "calc(100% - 40px)";
      if (this.props.item.children?.length > 0) {
        titleWidth = "calc(100% - 90px)";
      }
    } else {
      if (this.props.item.children?.length > 0) {
        titleWidth = "calc(100% - 50px)";
      }
    }
    const test = (
      <>
        {(this.state.document || this.props.item.title) ?
          <>
            {this.props.item.icon &&
              <div
                className='acccordionItemIcon'
                onClick={() => {
                  if (this.props.item.children?.length > 0) {
                    this.setState({ expanded: !this.state.expanded });
                  }
                }}
              >
                {this.props.item.icon}
              </div>
            }
            {this.state.document?.icon &&
              <Icon
                title={this.state.document.icon}
                size={24}
                color={"#ffffff"}
                style={{
                  float: "left",
                  marginTop: 11,
                  marginRight: 12
                }}
              />
            }
            <div
              className='acccordionItemTitle'
              style={{
                //width: titleWidth
              }}
              onClick={() => {
                if (this.props.item.children?.length > 0) {
                  this.setState({ expanded: !this.state.expanded });
                }
              }}
            >
              {this.props.item.title ? this.props.item.title : this.state.document?.documentTitle}
            </div>
            {this.props.preview &&
              <Icon
                title={"Lock"}
                size={24}
                color={"gray"}
                style={{
                  float: "left",
                  marginTop: 11,
                  marginLeft: 10
                }}
              />
            }
            {this.props.item.children?.length > 0 &&
              <div
                className='accordionItemExpandButton'
              >
                <Icon
                  title={"ArrowDown"}
                  size={20}
                  color={"#ffffff"}
                  style={{
                    transform: this.state.expanded ? "rotate(180deg)" : "rotate(0deg)",
                    transition: "transform 500ms ease-in-out",
                    cursor: "pointer"
                  }}
                  onClick={() => {
                    if (this.props.item.children?.length > 0) {
                      this.setState({ expanded: !this.state.expanded });
                    }
                  }}
                />
              </div>
            }
            <div
              className='acccordionItemBorder'
              style={{
                opacity: this.state.expanded || this.props.hideBorder ? 0 : 1
              }}
            />
            <div
              id={`accordionItemContent${this.props.item.id}`}
              className='accordionItemContent'
            >
              {this.props.item.children}
            </div>
          </>
          :
          <>
            <div
              className='acccordionItemTitle'
              style={{
                width: titleWidth,
                opacity: 0.5
              }}
            >
              {window.loc.strings.loading}
            </div>

            <div
              className='acccordionItemBorder'
              style={{
                opacity: this.state.expanded || this.props.hideBorder ? 0 : 1
              }}
            />
          </>
        }
      </>
    )
    return (
      this.props.preview ?
        <div
          className="acccordionItem"
          style={{
            height: this.state.expanded ? (contentHeight === 50 ? "auto" : contentHeight) : 50,
            backgroundColor: this.props.backgroundColor ?? "#272727",
            cursor: "default"
          }}
        >
          {test}
        </div>
        :
        <>
          {((this.state.document?.openInPopup || this.state.document?.openEmbedded) && !this.props.isMobile) &&
            <div
              className="acccordionItem"
              style={{
                height: this.state.expanded ? (contentHeight === 50 ? "auto" : contentHeight) : 50,
                backgroundColor: this.props.backgroundColor ?? "#272727",
                cursor: "pointer"
              }}
              onClick={() => {
                if (this.state.document?.openInPopup) {
                  this.props.openInPopup(this.state.document.url);
                }
                if (this.state.document?.openEmbedded) {
                  this.props.openInPageEmbed(this.state.document.url);
                }
              }}
            >
              {test}
            </div>
          }
          {(this.state.document?.mindPaperId) &&
            (this.props.isMobile ?
              <Link
                to={`?mindPaper=${this.state.document.mindPaperId}`}
                key={this.state.document.mindPaperId}
              >
                <div
                  className="acccordionItem"
                  style={{
                    height: this.state.expanded ? (contentHeight === 50 ? "auto" : contentHeight) : 50,
                    backgroundColor: this.props.backgroundColor ?? "#272727",
                    cursor: "pointer"
                  }}
                  onClick={() => {
                    this.props.openInPageEmbed(`https://dojo.mindjuice.com/mindPaper2?mindPaperId=${this.state.document.mindPaperId}`);
                  }}
                >
                  {test}
                </div>
              </Link>
              :
              <a
                href={`https://dojo.mindjuice.com/mindPaper2?mindPaperId=${this.state.document.mindPaperId}`}
                target='_blank'
                key={this.state.document.mindPaperId}
              >
                <div
                  className="acccordionItem"
                  style={{
                    height: this.state.expanded ? (contentHeight === 50 ? "auto" : contentHeight) : 50,
                    backgroundColor: this.props.backgroundColor ?? "#272727",
                    cursor: "pointer"
                  }}
                >
                  {test}
                </div>
              </a>
            )
          }
          {(!this.state.document || (this.state.document && !this.state.document.openEmbedded && !this.state.document.openInPopup && !this.state.document.mindPaperId) || (this.props.isMobile && !this.state.document.mindPaperId)) &&
            <a href={this.state.document?.url} target={this.state.document?.openInSameWindow ? '_self' : '_blank'}>
              <div
                className="acccordionItem"
                style={{
                  height: this.state.expanded ? (contentHeight === 50 ? "auto" : contentHeight) : 50,
                  backgroundColor: this.props.backgroundColor ?? "#272727"
                }}
              >
                {test}
              </div>
            </a>
          }
          {this.state.document?.url && (this.state.document?.url?.indexOf("youtube") !== -1 || this.state.document?.url?.indexOf("vimeo") !== -1) &&
            <iframe
              src={this.state.document.url}
              width="100%"
              height={(width - 40) / 16 * 9}
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
            />
          }
        </>
    );
  }
}